.lia-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.lia-pager {
  display: flex;
  align-items: center;
  padding: 0 12px;
  background-color: var(--lia-bs-light);
  border-radius: var(--lia-bs-border-radius-sm);

  &:hover,
  &:focus {
    background-color: var(--lia-bs-gray-400);
  }
}
