@import '@aurora/shared-client/styles/_variables.pcss';

.lia-guide-navigation {
  display: flex;
  column-gap: 20px;
  margin: 0 60px;
  border-top: 1px solid var(--lia-bs-border-color);
  padding: 40px 0 30px;

  @media (--lia-breakpoint-down-md) {
    margin: 0 20px;
  }
}
